import { render, staticRenderFns } from "./PremiumIntroductionPage.vue?vue&type=template&id=74b00cc6&scoped=true"
import script from "./PremiumIntroductionPage.vue?vue&type=script&lang=js"
export * from "./PremiumIntroductionPage.vue?vue&type=script&lang=js"
import style0 from "./PremiumIntroductionPage.vue?vue&type=style&index=0&id=74b00cc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b00cc6",
  null
  
)

export default component.exports