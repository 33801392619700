<template>
    <div class="premium-introduction">
        <i class="material-icons close-btn" @click="back">close</i>
        <div class="container">
            <img class="top-img" src="@/assets/images/premium/premium_01.png" />
            <img class="middle-img" src="@/assets/images/premium/premium_02.png" />
            <img class="bottom-img" src="@/assets/images/premium/premium_03.png" />
            <img src="@/assets/images/premium/premium_04.png" />
            <img src="@/assets/images/premium/premium_05.png" />
            <div class="blank" />
        </div>
        <div class="box-grad">
            <div class="btn-bf flex" @click="onClickButton" v-html="'프리미엄 주선 받기'" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PremiumIntroductionPage',
    props: {
        agent: {
            type: Object,
            default: null,
        },
        beforeBackgroundColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
    methods: {
        back() {
            this.$stackRouter.pop()
        },
        onClickButton() {
            if (!this.$isPremium()) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'premiumEvent',
                        option: {
                            funnel: 'click_purchase_premium_button',
                        },
                    },
                })
            }

            this.$stackRouter.push({
                name: 'PgStorePage',
                props: {
                    productType: 'premium',
                    agent: this.agent,
                    beforeBackgroundColor: '#0e0b54',
                },
            })
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#0E0B54',
                bottom: 'white',
            },
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: this.beforeBackgroundColor,
        })
    },
}
</script>

<style lang="scss" scoped>
.premium-introduction {
    height: 100vh;
    overflow: auto;

    .close-btn {
        position: fixed;
        left: 16px;
        top: 16px;
        color: white;
        z-index: 1;
        width: 28px;
        height: 28px;
        padding: 2px;
        border-radius: 14px;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .btn-bf {
        width: calc(100vw - (24px * 2));
        height: 48px;
        border-radius: 8px;
        border: none;
        font-size: 15px;
        color: #211d76;
    }

    .container {
        position: relative;

        img {
            width: 100vw;
        }

        .top-img {
            position: inherit;
            display: flex;
        }

        .btn-bf {
            position: absolute;
            left: 24px;
            bottom: 68px;
            background-color: $yellow-intensive;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        }

        .middle-img {
            position: inherit;
            display: flex;
        }

        .bottom-img {
            position: inherit;
            display: flex;
        }

        .blank {
            position: inherit;
            display: flex;
            width: 100vw;
            height: 80px;
        }
    }

    .box-grad {
        position: fixed;
        width: 100vw;
        height: 80px;
        bottom: 0;
        @include center;

        .btn-bf {
            background: none;
            color: white;
            padding-top: 16px;
            padding-bottom: 16px;
            background-image: $yellow-premium-grad-event;
            font-size: 15px;

            @include center;
            @include f-bold;
        }
    }
}
</style>
